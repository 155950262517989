import { useState, useEffect } from 'react';
import { useRouter } from 'next/router' 

import PageWithNav from 'layouts/PageWithNav';

import { Container, H1, Loading } from 'element';


export default function Custom404() {
  // const [loading, setLoading] = useState<boolean>(true)
  // const router = useRouter();

  // useEffect(()=> {
  //   if (router.asPath.match(/\/users\/[^\/]+\/?$/) || 
  //       router.asPath.match(/\/users\/[^\/]+\/edit\/?$/) || 
  //       router.asPath.match(/\/user\/password\/reset\/token\/[^\/]+\/?$/) ||
  //       router.asPath.match(/\/clients\/[^\/]+\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/edit\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/configuration\/?$/) ||
  //       router.asPath.match(/\/clients\/[^\/]+\/configuration\/products\/?$/) ||
  //       router.asPath.match(/\/clients\/[^\/]+\/configuration\/products\/new\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/configuration\/products\/[^\/]+\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/reports\/?$/) ||
  //       router.asPath.match(/\/clients\/[^\/]+\/reports\/[^\/]+\/?$/) ||
  //       router.asPath.match(/\/clients\/[^\/]+\/carts\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/carts\/new\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/carts\/[^\/]+\/?$/) || 
        
  //       router.asPath.match(/\/clients\/[^\/]+\/payments\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/payments\/new\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/payments\/[^\/]+\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/payments\/[^\/]+\/edit\/?$/) || 

  //       router.asPath.match(/\/clients\/[^\/]+\/settlements\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/settlements\/[^\/]+\/?$/) || 
        
  //       router.asPath.match(/\/clients\/[^\/]+\/receivables\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/receivables\/[^\/]+\/?$/) || 


  //       router.asPath.match(/\/clients\/[^\/]+\/insured\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/insured\/new\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/insured\/[^\/]+\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/insured\/[^\/]+\/edit\/?$/) || 
        
  //       router.asPath.match(/\/clients\/[^\/]+\/insured\/[^\/]+\/carts\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/insured\/[^\/]+\/carts\/new\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/insured\/[^\/]+\/carts\/[^\/]+\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/insured\/[^\/]+\/carts\/[^\/]+\/review\/?$/) || 
        
  //       router.asPath.match(/\/clients\/[^\/]+\/insured\/[^\/]+\/correspondence\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/insured\/[^\/]+\/correspondence\/new\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/insured\/[^\/]+\/correspondence\/[^\/]+\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/insured\/[^\/]+\/correspondence\/[^\/]+\/review\/?$/) || 
        
  //       router.asPath.match(/\/clients\/[^\/]+\/agency\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/agency\/[^\/]+\/?$/) || 
        
  //       router.asPath.match(/\/clients\/[^\/]+\/agency\/[^\/]+\/carts\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/agency\/[^\/]+\/carts\/new\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/agency\/[^\/]+\/carts\/[^\/]+\/?$/) || 
  //       router.asPath.match(/\/clients\/[^\/]+\/agency\/[^\/]+\/carts\/[^\/]+\/review\/?$/) || 



  //       router.asPath.match(/\/carts\/[^\/]+\/?$/) || 
  //       router.asPath.match(/\/carts\/[^\/]+\/agreements\/?$/) || 
  //       router.asPath.match(/\/carts\/[^\/]+\/pay\/?$/) ||
  //       router.asPath.match(/\/carts\/[^\/]+\/review\/?$/)
  //     ) {
  //     router.replace(router.asPath);
  //   } else {
  //     setLoading(false);
  //   }
  // }, [router])

  //const content = loading ? <Loading /> : <H1>404 - Page Not Found</H1>
  const content = <H1>404 - Page Not Found</H1>

  return <PageWithNav>
      <Container>
        {content}
      </Container>
    </PageWithNav>
}