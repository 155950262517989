import { ReactNode } from 'react'
import AppNav from 'component/AppNav';

import { styled } from '@mui/material/styles';
import AppBarNotification from 'element/AppBarNotification';
import { BreadCrumbs, Container } from 'element';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';

const StyledMain = styled('div')(({theme}) => `
  padding: ${theme.spacing(4)} 0;
  position: relative;
`)


export default function PageWithNav({
  setOpen,
  children,
  notification,
  onHideNotification,
  successMessage,
  errorMessage,
  breadCrumbs
}: {
  setOpen?(open: boolean): void
  children: ReactNode
  notification?: ReactNode
  onHideNotification?: ()=>void
  successMessage?: string
  errorMessage?: string
  breadCrumbs?: ReactNode
}) {
  
  return <>
    <AppNav setOpen={setOpen}/>
    {notification && <AppBarNotification  onHide={onHideNotification}>{notification}</AppBarNotification>}
    {successMessage && <AppBarNotification onHide={onHideNotification} success>{successMessage}</AppBarNotification>}
    {errorMessage && <AppBarNotification onHide={onHideNotification} error>{errorMessage}</AppBarNotification>}
    <StyledMain>
      {breadCrumbs && <Box sx={{ml: 2, mb: 2}}>{breadCrumbs}</Box>}
      {children}
    </StyledMain>
  </>
}
